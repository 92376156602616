import { render, staticRenderFns } from "./Confirmation.vue?vue&type=template&id=432b2f8e&scoped=true&"
import script from "./Confirmation.vue?vue&type=script&lang=js&"
export * from "./Confirmation.vue?vue&type=script&lang=js&"
import style0 from "./Confirmation.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Confirmation.vue?vue&type=style&index=1&id=432b2f8e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "432b2f8e",
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QInput,QField,QDialog});
